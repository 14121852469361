import React, { useEffect } from "react";
import { useRaceResults } from "../../../contexts/RaceResults.context";
import BaseCard from "../../ui/BaseCard";
const RaceResultTable = () => {
  const { venues, raceList, filteredRaceList, changeVenue } = useRaceResults();
  const [racesToDisplay, setRacesToDisplay] = React.useState([]);

  const sortRaceList = (filteredRaceList) => {
    const uniqueRaces = [
      ...new Set(filteredRaceList.map((race) => race.market_id)),
    ];
    console.log(uniqueRaces);
    let newRaces = uniqueRaces.map((race) => {
      let races2 = filteredRaceList.filter((item) => item.market_id === race);

      return {
        race: races2[0].race_name,
        race_number: races2[0].race_number,
        total_matched: races2[0].race_total_matched,
        venue: races2[0].venue,
        results: races2,
      };
    });

    console.log(newRaces);

    setRacesToDisplay(newRaces.reverse());
  };

  useEffect(() => {
    if (filteredRaceList.length > 0) {
      sortRaceList(filteredRaceList);
    }
  }, [filteredRaceList]);

  return (
    <BaseCard>
      {racesToDisplay.length > 0 ? (
        <div className="flex flex-col">
          {racesToDisplay.map((race) => (
            <>
              <div className="w-full bg-lbw-secondary-lighter py-2 px-2">
                <span className="text-neutral-50 text-left text-xl font-cairoli">
                  {race.race}
                </span>
                <span className="text-neutral-50 text-xl float-right font-cairoli">
                  Total Matched: ${race.total_matched}
                </span>
              </div>
              <div className="w-full">
                <RaceTable data={race.results} />
              </div>
            </>
          ))}
        </div>
      ) : (
        <></>
      )}
    </BaseCard>
  );
};

const RaceTable = ({ data }) => {
  return (
    <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th className=" text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
            Selection
          </th>
          <th className=" text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
            Rank
          </th>
          <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
            Percentage
          </th>
          <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-right">
            Result
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((r) => (
          <tr
            className={`[&>td]:py-3 font-bold text-neutral-100 border-b-2 border-solid border-slate-400`}
          >
            <td
              className={` border-t-0 align-middle border-l-0 border-r-0 text-xs lg:whitespace-nowrap p-4 text-left`}
            >
              {r.runner_name}
            </td>
            <td className="align-middle text-xs lg:whitespace-nowrap">
              {r.rank}
            </td>
            <td className="align-middle text-xs lg:whitespace-nowrap">
              {r.percentage.toFixed(2)}%
            </td>
            <td className="align-middle text-xs lg:whitespace-nowrap">
              {r.result}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RaceResultTable;
