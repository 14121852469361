import React from "react";
import BaseCard from "../../components/ui/BaseCard";
import UserTable from "../../components/Admin/UserTable";
import { AddUser } from "../../components/Admin/AddUser";

const UserManagement = () => {
  return (
    <div className="flex flex-wrap space-y-4 pt-4 lg:pt-12">
      <div className="w-full">
        <BaseCard>
          <UserTable />
        </BaseCard>
      </div>
      <div className="w-full">
        <BaseCard>
          <AddUser />
        </BaseCard>
      </div>
    </div>
  );
};

export default UserManagement;
