import React, { useState, useEffect } from "react";
import BaseCard from "../../ui/BaseCard";
import moment from "moment";
import { useRaceResults } from "../../../contexts/RaceResults.context";

const VenueFilter = () => {
  const { venues, raceList, changeVenue } = useRaceResults();
  useEffect(() => {
    console.log("venues", venues);
  }, [venues]);
  return (
    <BaseCard>
      <div className="flex flex-row">
        <div className="flex flex-wrap">
          {venues.map((venue, index) => (
            <div
              onClick={() => changeVenue(venue)}
              className={`pt-2 pb-4 px-10 ${
                2 === 3 ? "bg-lbw-secondary-lighter cursor-default" : ""
              } hover:bg-lbw-secondary-lighter cursor-pointer rounded-lg`}
            >
              <p className="text-neutral-100 font-cairoli p-0">{venue}</p>
            </div>
          ))}
        </div>
        {/* <div className="w-3/4 flex flex-row">
          {raceList
            .filter((race) => race.inView === true)
            .map((race, index) => (
              <div
                className={`pt-2 pb-4 px-10 ${
                  2 === 3 ? "bg-lbw-secondary-lighter cursor-default" : ""
                } hover:bg-lbw-secondary-lighter cursor-pointer rounded-lg`}
              >
                <p className="text-neutral-100 font-cairoli p-0">
                  {race.race_number}
                </p>
              </div>
            ))}
        </div> */}
      </div>
    </BaseCard>
  );
};

export default VenueFilter;
