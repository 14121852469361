import React from "react";
import { useEffect, useState } from "react";
import { StarterPackagePanel } from "../../components/Admin/StarterPackageSettings/StarterPackagePanel";
import { UpcomingRaces } from "../../components/Admin/StarterPackageSettings/UpcomingRaces";

export default function StarterPackageSettings() {
  const [currentPage, setCurrentPage] = useState("races");

  return (
    <div className="h-full bg-lbw-primary py-6">
      <div className="flex flex-wrap">
        <div className="w-full mb-12 xl:mb-0 pb-4 space-y-4">
          <StarterPackagePanel />
        </div>
        <div className="w-full mb-12 xl:mb-0 space-y-4">
          <div className="flex flex-wrap justify-center">
            <div className="w-1/2 pr-2"></div>
          </div>
          <div>
            <UpcomingRaces />
          </div>
        </div>
      </div>
    </div>
  );
}
