import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const CustomLabel = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip bg-neutral-200 p-2 font-bold">
        {payload[0].payload.profit ? (
          <>
            <p className="label">
              {payload[0].payload.event_name} -{" "}
              {payload[0].payload.selection_name}
            </p>
            <p className="intro">Selection: </p>
            <p className="side">
              {payload[0].payload.side} @ $
              {payload[0].payload.order_price.toFixed(2)}
            </p>
            <p className="desc">
              Result: ${payload[0].payload.profit.toFixed(2)}
            </p>
          </>
        ) : (
          <p className="intro">Order not settled/matched </p>
        )}
      </div>
    );
  }
};

const LineChartResults = ({ results }) => {
  const [tooltipData, setTooltipData] = useState([]);
  const [calcHeight, setCalcHeight] = useState(0);

  window.addEventListener("resize", () => {
    const heightCalc = document.getElementById("upcoming-results").offsetHeight;
    // console.log(heightCalc);
    setCalcHeight(heightCalc - 45);
  });

  useEffect(() => {
    const heightCalc = document.getElementById("upcoming-results").offsetHeight;
    // console.log(heightCalc);
    setCalcHeight(heightCalc - 45);
  }, []);

  useEffect(() => {
    const heightCalc = document.getElementById("upcoming-results").offsetHeight;
    // console.log(heightCalc);
    setCalcHeight(heightCalc - 45);
  }, [results]);
  return (
    <ResponsiveContainer
      width="100%"
      height={window.screen.width < 768 ? 200 : calcHeight}
    >
      <LineChart
        width={1200}
        height={300}
        data={results}
        margin={{
          top: 5,
          right: 0,
          left: -30,
          bottom: 5,
        }}
      >
        <defs>
          <filter id="shadow" height="200%">
            <feDropShadow
              dx="0"
              dy="10"
              stdDeviation={10}
              floodColor="#0d85f2"
            />
          </filter>
        </defs>
        {/* <CartesianGrid vertical={false} strokeDasharray={2} /> */}
        <XAxis
          hide={window.screen.width > 768 ? false : true}
          dataKey="date"
          type="category"
        />
        <YAxis type="number" name="profitU" />
        {results.length > 0 ? (
          <Tooltip content={<CustomLabel />} />
        ) : (
          <Tooltip />
        )}

        {/* <Legend iconSize={0} /> */}
        <Line
          type="monotone"
          name="Running P&L"
          dataKey="running_profit"
          stroke="#0d85f2"
          filter="url(#shadow)"
          dot={false}
          activeDot={{ r: 0 }}
        />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartResults;
