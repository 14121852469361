import React, { useEffect, useState } from "react";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import BotDetailsCard from "../../components/Admin/DevelopmentBots/BotDetailsCard";
import AddNewBot from "../../components/Admin/DevelopmentBots/AddNewBot";
const DevelopmentBots = () => {
  const [bots, setBots] = useState([]);
  const [newBotWindow, setNewBotWindow] = useState(false);

  const getBots = async () => {
    const { data, error } = await supabase
      .from("development_bots")
      .select("*")
      .order("bot_active", { ascending: false })
      .order("created_at", { ascending: true });
    console.log(data);
    if (error) {
      console.log(error);
    } else {
      setBots(data);
    }
  };

  useEffect(() => {
    getBots();
  }, []);
  return (
    <div className="bg-lbw-primary pt-[8%]">
      <BaseCard>
        <div className="my-4 flex flex-wrap">
          <div className="w-10/12 my-auto">
            <h2 className="text-3xl text-neutral-100">Development Bots</h2>
          </div>
          <div className="w-2/12">
            <button
              onClick={() => setNewBotWindow(true)}
              className="bg-lbw-accent w text-neutral-900 hover:bg-[#c55b43] text-sm font-bold uppercase 
                px-2 py-3 lg:py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 lg:my-4 w-full 
                ease-linear transition-all duration-150"
              type="button"
            >
              Add New +
            </button>
          </div>
        </div>
        {newBotWindow ? (
          <div className="w-full px-2 py-3">
            <AddNewBot />
          </div>
        ) : null}
      </BaseCard>
      <div className="my-4 flex flex-wrap">
        {bots.map((bot) => (
          <div className="w-full px-2 py-3">
            <BotDetailsCard bot={bot} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevelopmentBots;
