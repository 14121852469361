import React from "react";
import axios from "axios";
import { supabase } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";
import { HorseIcon, TickIcon, HarnessIcon, GreyhoundIcon } from "../../ui/Icon";

export const CaptainsLoungeBotSettings = ({ raceType }) => {
  const [strategyId, setStrategyId] = React.useState(null);
  const [botSettings, setBotSettings] = React.useState({
    strategy_id: 0,
    stake: 0,
    take_profit: 0,
    stop_loss: 0,
    race_type: raceType,
    rank: 0,
    percent: 0,
    time_before_jump: 0,
    active: false,
    minimum_runners: 0,
    minimum_volume: 0,
    maximum_odds: 0.0,
    minimum_odds: 0.0,
    scheduling: false,
    start_time: "00:00:00",
    end_time: "00:00:00",
    bet_side: "Both",
  });
  const [settingsSavedSuccess, setSettingsSavedSuccess] = React.useState(null);
  const [noBotSettings, setNoBotSettings] = React.useState(true);
  const [showAddStrategy, setShowAddStrategy] = React.useState(false);

  const { user } = useUser();

  const getMasterSettings = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .select("*")
      .eq("user_id", user.auth_id)
      .eq("race_type", raceType);
    if (data.length > 0) {
      setNoBotSettings(false);
      setBotSettings({
        stake: data[0].stake,
        rank: data[0].rank,
        take_profit: data[0].take_profit,
        stop_loss: data[0].stop_loss,
        percent: data[0].percent,
        time_before_jump: data[0].time_before_jump,
        active: data[0].active,
        minimum_runners: data[0].minimum_runners,
        minimum_volume: data[0].minimum_volume,
        maximum_odds: data[0].maximum_odds,
        minimum_odds: data[0].minimum_odds,
        scheduling: data[0].scheduling,
        start_time: data[0].start_time,
        end_time: data[0].end_time,
        bet_side: data[0].bet_side,
      });
      setStrategyId(data[0].strategy_id);
    }
    if (data.length === 0) {
      setNoBotSettings(true);
    }
    if (error) {
      console.log(error);
    }
  };

  const saveSettingsHandler = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .update(botSettings)
      .eq("strategy_id", strategyId)
      .select("*");
    console.log(data, error);
    getMasterSettings();
  };

  const botStatusHandler = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .update({ active: !botSettings.active })
      .eq("strategy_id", strategyId)
      .select("*");
    getMasterSettings();
  };

  React.useEffect(() => {
    getMasterSettings();
  }, []);

  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center">
        <h2 className="block uppercase text-neutral-100 text-md font-bold">
          {raceType} Strategy Settings
        </h2>
      </div>
      {noBotSettings === false ? (
        <div className="flex flex-row w-full gap-x-2 py-4 gap-y-5">
          {/* System Settings */}
          <div className="w-full ">
            <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-12">
              <div className="w-1/3">
                <label className={labelStyle}>Rank</label>
                <input
                  id="rank"
                  type="number"
                  value={botSettings.rank}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      rank: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Percent</label>
                <input
                  id="percent"
                  type="number"
                  value={botSettings.percent}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      percent: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Stake</label>
                <input
                  id="rank"
                  type="number"
                  value={botSettings.stake}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      stake: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
            </div>
            <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-12">
              <div className="w-1/3">
                <label className={labelStyle}>Take Profit</label>
                <input
                  id="percent"
                  type="number"
                  value={botSettings.take_profit}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      take_profit: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Stop Loss</label>
                <input
                  id="rank"
                  type="number"
                  value={botSettings.stop_loss}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      stop_loss: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3"></div>
            </div>
            <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
              <div className="w-1/3">
                <label className={labelStyle}>Minimum Volume</label>
                <input
                  id="take_profit"
                  type="number"
                  value={botSettings.minimum_volume}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      minimum_volume: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Minimum Odds $</label>
                <input
                  id="percent"
                  type="number"
                  value={botSettings.minimum_odds}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      minimum_odds: parseFloat(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Max Odds $</label>
                <input
                  id="percent"
                  type="number"
                  value={botSettings.maximum_odds}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      maximum_odds: parseFloat(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
            </div>
            <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
              <div className="w-1/3">
                <label className={labelStyle}>Min. Runners</label>
                <input
                  id="take_profit"
                  type="number"
                  value={botSettings.minimum_runners}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      minimum_runners: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Back or Lay Only</label>
                <select
                  className={inputStyle}
                  value={botSettings.bet_side}
                  onChange={(e) => {
                    setBotSettings({
                      ...botSettings,
                      bet_side: e.target.value,
                    });
                  }}
                >
                  <option value="Both">Both</option>
                  <option value="Back">Back</option>
                  <option value="Lay">Lay</option>
                </select>
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>Before Jump</label>
                <input
                  id="percent"
                  type="number"
                  value={botSettings.time_before_jump}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      time_before_jump: parseInt(e.target.value),
                    })
                  }
                  className={inputStyle}
                />
              </div>
            </div>
            <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
              {/* <div className="w-1/3"></div>
              <div className="w-1/3">
                <label className={labelStyle}>Start time</label>
                <input
                  id="percent"
                  type="number"
                  className={inputStyle}
                  value={botSettings.start_time}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      start_time: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
              <div className="w-1/3">
                <label className={labelStyle}>End Time</label>
                <input
                  id="percent"
                  type="number"
                  className={inputStyle}
                  value={botSettings.end_time}
                  onChange={(e) =>
                    setBotSettings({
                      ...botSettings,
                      end_time: parseFloat(e.target.value),
                    })
                  }
                />
              </div> */}
            </div>
            <button
              onClick={botStatusHandler}
              className={
                botSettings.active === true
                  ? "bg-red-600 active:bg-red-600 " +
                    "text-sm font-bold uppercase text-neutral-100 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                  : "bg-green-600 active:bg-green-600" +
                    "text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              }
            >
              {botSettings.active ? "Deactivate Strategy" : "Activate Strategy"}
            </button>
            <button onClick={saveSettingsHandler} className={buttonStyle}>
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-center">
            <button
              className={buttonStyle}
              onClick={() => setShowAddStrategy(true)}
            >
              Create Strategy +
            </button>
          </div>
          {showAddStrategy ? (
            <AddStrategyPopUp
              type={raceType}
              setCloseModal={setShowAddStrategy}
              user={user}
            />
          ) : (
            <></>
          )}
          {/* <AddStrategyPopUp /> */}
        </>
      )}
    </div>
  );
};

const AddStrategyPopUp = ({ type, user, setCloseModal }) => {
  const [newStratSettings, setNewStratSettings] = React.useState({
    stake: 0,
    take_profit: 0,
    stop_loss: 0,
    rank: 0,
    percent: 0,
    race_type: type,
    time_before_jump: 0,
    active: false,
    minimum_runners: 0,
    minimum_volume: 0,
    maximum_odds: 0.0,
    minimum_odds: 0.0,
    scheduling: false,
    start_time: "00:00:00",
    end_time: "00:00:00",
    bet_side: "Both",
  });

  const saveNewStrategy = async () => {
    const { data, error } = await supabase
      .from("cl_user_strategies")

      .insert([{ ...newStratSettings, user_id: user.auth_id }])
      .select("*");
    console.log(data, error);
    if (data) {
      setTimeout(() => {
        setCloseModal(false);
      }, 1000);
    } else {
      console.log(error);
    }
  };

  return (
    <div className="absolute max-sm:pt-20 max-sm:px-2 pt-40 px-20 -top-3 left-0 z-50 h-screen w-full bg-black bg-opacity-60">
      <div className="flex bg-lbw-secondary px-10 flex-row w-full gap-x-2 py-10 gap-y-5 rounded-xl">
        {/* System Settings */}
        <div className="w-full ">
          <div className="flex flex-row pb-6 w-full justify-center">
            <h2 className="block uppercase text-neutral-100 text-md font-bold">
              Create {type} Strategy
            </h2>
          </div>
          <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-12">
            <div className="w-1/3">
              <label className={labelStyle}>Stake</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.stake}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    stake: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Rank</label>
              <input
                id="rank"
                type="number"
                className={inputStyle}
                value={newStratSettings.rank}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    rank: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Percent</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.percent}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    percent: parseInt(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-12">
            <div className="w-1/3">
              <label className={labelStyle}>Take Profit</label>
              <input
                id="percent"
                type="number"
                value={newStratSettings.take_profit}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    take_profit: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Stop Loss</label>
              <input
                id="rank"
                type="number"
                value={newStratSettings.stop_loss}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    stop_loss: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3"></div>
          </div>
          <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
            <div className="w-1/3">
              <label className={labelStyle}>Min. Volume</label>
              <input
                id="take_profit"
                type="number"
                className={inputStyle}
                value={newStratSettings.minimum_volume}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    minimum_volume: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Min. Odds $</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.minimum_odds}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    minimum_odds: parseFloat(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Max Odds $</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.maximum_odds}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    maximum_odds: parseFloat(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
            <div className="w-1/3">
              <label className={labelStyle}>Min. Runners</label>
              <input
                id="take_profit"
                type="number"
                className={inputStyle}
                value={newStratSettings.minimum_runners}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    minimum_runners: parseFloat(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>
                {window.screen.width > 768 ? "Back or Lay Only" : "Back or Lay"}
              </label>
              <select
                className={inputStyle}
                value={newStratSettings.bet_side}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    bet_side: e.target.value,
                  })
                }
              >
                <option value="Both">Both</option>
                <option value="Back">Back</option>
                <option value="Lay">Lay</option>
              </select>
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Before Jump</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.time_before_jump}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    time_before_jump: parseInt(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 max-sm:gap-x-2 gap-x-10">
            {/* <div className="w-1/3"></div>
            <div className="w-1/3">
              <label className={labelStyle}>Start time</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.start_time}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    start_time: parseFloat(e.target.value),
                  })
                }
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>End Time</label>
              <input
                id="percent"
                type="number"
                className={inputStyle}
                value={newStratSettings.end}
                onChange={(e) =>
                  setNewStratSettings({
                    ...newStratSettings,
                    end_time: parseFloat(e.target.value),
                  })
                }
              />
            </div> */}
          </div>
          <div className="flex flex-row gap-x-10">
            <div className="w-1/2">
              <button
                className={`${buttonStyle} bg-red-500`}
                onClick={() => setCloseModal(false)}
              >
                Cancel
              </button>
            </div>
            <div className="w-1/2">
              <button className={buttonStyle} onClick={saveNewStrategy}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const labelStyle =
  "block uppercase text-neutral-100 text-xs font-bold mb-2 my-auto mr-4 whitespace-nowrap";
const inputStyle =
  "border-2 border-gray-400 px-3 py-3 placeholder-gray-300 text-neutral-100 font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-white active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";
