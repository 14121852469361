import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/User.context";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "./Icon";
import { getUsersUpcomingRaces } from "../../utils/user";
import CountdownText from "./CountdownText";
import { betfairAccountRequest } from "../../utils/betfair";

export const StatusBar = () => {
  const { user, refreshUser } = useUser();
  const [status, setStatus] = useState("Loading...");
  const [marketData, setMarketData] = useState([]);
  const [balance, setBalance] = useState(0);

  const getBalance = async () => {
    let accessToken = user.betfair_access_token;
    let data = {
      wallet: "UK",
    };
    if (accessToken !== undefined || null) {
      let result = await betfairAccountRequest(
        accessToken,
        "getAccountFunds",
        data
      );

      if (result.data.error?.code) {
        refreshUser();
      }
      setBalance(result.data.result.availableToBetBalance);
    } else {
      setBalance(0);
    }
  };

  const refreshBalance = async () => {
    setBalance("Refreshing...");
    getBalance();
  };

  const racingData = async () => {
    // console.log(user);
    const data = await getUsersUpcomingRaces(user);
    // console.log(data);
    setMarketData(data);
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
    getBalance();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      racingData();
      getBalance();
    }, 20000);
    return () => clearInterval(interval);
  }, [marketData]);

  return (
    <div className="max-sm:hidden relative top-0 right-0 z-10 py-4 px-8 bg-lbw-secondary w-full">
      <div className="flex flex-row">
        <div className="w-3/4">
          {marketData.length > 0 ? (
            <div className="flex flex-row space-x-8 overflow-x-auto scroller">
              {marketData?.map((item) => (
                <div className="flex flex-row my-auto">
                  {item.race_type === "Thoroughbred" ? (
                    <HorseIcon className="text-3xl font-thin text-neutral-50 mr-2" />
                  ) : item.race_type === "Greyhound" ? (
                    <GreyhoundIcon className="text-3xl font-thin text-neutral-50 mr-2" />
                  ) : (
                    <HarnessIcon className="text-xl font-thin text-neutral-50 mr-2" />
                  )}
                  <div className="p-1">
                    <p className="flex flex-row  whitespace-nowrap text-neutral-50">
                      {item.race_number} {item.venue}
                    </p>
                  </div>
                  <div
                    className={`my-auto px-2 text-md rounded-md py-1 flex flex-row ${
                      item.redTime
                        ? "bg-red-700 text-neutral-50"
                        : "bg-lbw-primary text-neutral-50"
                    }`}
                  >
                    <div className="flex flex-row whitespace-nowrap">
                      <CountdownText
                        jumpTime={item.market_start_time}
                        className={"text-neutral-50"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>No Upcoming Races</>
          )}
        </div>
        <div className="w-1/4 flex justify-around flex-row">
          <div className=" w-1/2 align-middle text-center text-neutral-300 text-xl whitespace-nowrap uppercase font-cairoli block no-underline">
            <span className={user.tbb4_bot ? "text-green-500" : "text-red-500"}>
              {user.tbb4_bot ? "ACTIVE" : "INACTIVE"}
            </span>
          </div>
          <div className=" w-1/2 align-middle text-right text-neutral-300 text-xl uppercase font-cairoli block no-underline">
            <span>${balance}</span>
            <i
              onClick={refreshBalance}
              className=" pl-1 fas fa-arrows-rotate mr-2 text-sm cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
