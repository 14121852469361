import React, { useState, useEffect } from "react";
import BaseCard from "../../ui/BaseCard";
import moment from "moment";
import { useRaceResults } from "../../../contexts/RaceResults.context";
const DateFilter = () => {
  const { changeDate, dates } = useRaceResults();
  return (
    <BaseCard>
      <div className="flex flex-row-reverse justify-around">
        {dates.map((date, index) => (
          <div
            className={`pt-2 pb-4 px-10 ${
              date.active ? "bg-lbw-secondary-lighter cursor-default" : ""
            } hover:bg-lbw-secondary-lighter cursor-pointer rounded-lg`}
            onClick={() => {
              changeDate(index);
            }}
          >
            <p className="text-neutral-100 font-cairoli p-0">{date.label}</p>
          </div>
        ))}
      </div>
    </BaseCard>
  );
};

export default DateFilter;
