import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const supabaseUrl = "https://sb.laybackandwin.au";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml6c3BjdG55eW1kZ3Jlb3htb2VnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM2MTg1OTAsImV4cCI6MjAwOTE5NDU5MH0.QPi1YUJc8WolXyoMGJWuzDa9x2jGAd794mElafHWecM";

const supabase = createClient(supabaseUrl, supabaseKey);

const getUser = async () => {
  const { data: session } = await supabase.auth.getSession();
  // console.log(session);
  if (!session) {
    return null;
  }
  const { data: user, error } = await supabase
    .from("user_information_full")
    .select("*")
    .eq("auth_id", session.session.user.id);

  return user[0];
};

export const invokeFunction = async (functionName, params) => {
  const url = `https://sb.laybackandwin.au/functions/v1/${functionName}`;

  const headers = {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml6c3BjdG55eW1kZ3Jlb3htb2VnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM2MTg1OTAsImV4cCI6MjAwOTE5NDU5MH0.QPi1YUJc8WolXyoMGJWuzDa9x2jGAd794mElafHWecM",
    "Content-Type": "application/json",
  };

  var config = {
    method: "post",
    url: url,
    headers: headers,
    data: params,
    crossdomain: true,
  };

  let d = await axios(config);

  return d;
};

export { supabase, getUser, supabaseKey };
