import React from "react";
import axios from "axios";
import { supabase } from "../../../utils/supabaseClient";
import { GreyhoundIcon, HarnessIcon, HorseIcon, TickIcon } from "../../ui/Icon";
export const TBB4AdminSettingsPanel = () => {
  const [botStatus, setBotStatus] = React.useState(false);

  const [botSettings, setBotSettings] = React.useState({
    stake_size: 0,
    rank: 0,
    percent: 0,
    max_lay: 1,
    time_before_jump: 0,
    active: false,
    take_profit: 0,
    stop_loss: 0,
    min_runners: 0,
    horse: false,
    harness: false,
    greyhound: false,
  });
  const [settingsSavedSuccess, setSettingsSavedSuccess] = React.useState(null);

  const getMasterSettings = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .select("*")
      .eq("id", 1);
    setBotSettings(data[0]);
  };

  const saveSettingsHandler = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .upsert(botSettings)
      .eq("id", 1)
      .select("*");
    console.log(data, error);
    getMasterSettings();
  };

  const botStatusHandler = async () => {
    const { data, error } = await supabase
      .from("tbb4_master_settings")
      .update({ active: !botSettings.active })
      .eq("id", 1)
      .select("*");
    getMasterSettings();
  };

  React.useEffect(() => {
    getMasterSettings();
  }, []);

  return (
    <div className="px-4 py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center">
        <h2 className="block uppercase text-neutral-100 text-md font-bold">
          Intermediate Settings
        </h2>
      </div>
      <div className="flex flex-row w-full gap-x-2 py-4 gap-y-5">
        {/* System Settings */}
        <div className="w-full ">
          <h2 className="block uppercase text-neutral-100 text-center underline text-md pb-2 font-bold">
            System Settings
          </h2>
          <div className="flex flex-row pb-6 gap-x-2">
            <div className="w-1/2">
              <label className={labelStyle}>Rank</label>
              <input
                id="rank"
                type="number"
                value={botSettings.rank}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    rank: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/2">
              <label className={labelStyle}>Percent</label>
              <input
                id="percent"
                type="number"
                value={botSettings.percent}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    percent: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 gap-x-2">
            <div className="w-1/3">
              <label className={labelStyle}>Min. Runners</label>
              <input
                id="take_profit"
                type="number"
                value={botSettings.min_runners}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    min_runners: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Max Lay</label>
              <input
                id="percent"
                type="number"
                value={botSettings.max_lay}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    max_lay: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Before Jump</label>
              <input
                id="percent"
                type="number"
                value={botSettings.time_before_jump}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    time_before_jump: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
          </div>
          <div className="flex flex-row pb-6 gap-x-10">
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.horse}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          horse: !botSettings.horse,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0"></div>
                    <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                    <HorseIcon className="ml-3 text-5xl text-white" />
                  </label>
                </div>
              </div>
            </div>
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.harness}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          harness: !botSettings.harness,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0 peer-checked:bg-white"></div>
                    <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                    <HarnessIcon className="ml-3 text-5xl font-light text-white" />
                  </label>
                </div>
              </div>
            </div>
            <div className="w1/3">
              <div className="relative w-full">
                <div className="flex gap-x-4 pt-2 justify-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={botSettings.greyhound}
                      onClick={() =>
                        setBotSettings({
                          ...botSettings,
                          greyhound: !botSettings.greyhound,
                        })
                      }
                      className="sr-only peer"
                    />
                    <div className="w-6 h-6 font-thin bg-transparent border-2 rounded-full peer-checked:border-0"></div>
                    <TickIcon className=" -ml-[2px] hidden peer-checked:text-lbw-accent peer-checked:block absolute font-thin text-[27px] " />
                    <GreyhoundIcon className="ml-3 text-5xl font-light text-white" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={botStatusHandler}
            className={
              botSettings.active === true
                ? "bg-red-600 active:bg-red-600 " +
                  "text-sm font-bold uppercase text-neutral-100 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 w-full ease-linear transition-all duration-150"
                : "bg-green-600 active:bg-green-600" +
                  "text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            }
          >
            {botSettings.active ? "Stop Bot" : "Start Bot"}
          </button>
          <button onClick={saveSettingsHandler} className={buttonStyle}>
            Save
          </button>
        </div>
        {/* Starter Package Settings */}
        {/* <div className="w-1/2 lg:pl-12">
          <h2 className="block uppercase text-neutral-100 text-center underline text-md pb-2 font-bold">
            Starter Package Settings
          </h2>
          <div className="flex flex-row pb-6 gap-x-2">
            <div className="w-1/3">
              <label className={labelStyle}>Stake Size</label>
              <input
                id="stake_size"
                type="number"
                value={botSettings.stake_size}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    stake_size: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Take Profit</label>
              <input
                id="take_profit"
                type="number"
                value={botSettings.take_profit}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    take_profit: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
            <div className="w-1/3">
              <label className={labelStyle}>Stop Loss</label>
              <input
                id="stop_loss"
                type="number"
                value={botSettings.stop_loss}
                onChange={(e) =>
                  setBotSettings({
                    ...botSettings,
                    stop_loss: parseInt(e.target.value),
                  })
                }
                className={inputStyle}
              />
            </div>
          </div>
          <button
            onClick={botStatusHandler}
            className={
              botSettings.active === true
                ? "bg-red-600 active:bg-red-600 " +
                  "text-sm font-bold uppercase text-neutral-100 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                : "bg-green-600 active:bg-green-600" +
                  "text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            }
          >
            {botSettings.active ? "Stop Bot" : "Start Bot"}
          </button>
        </div> */}
      </div>
    </div>
  );
};

const labelStyle = "block uppercase text-neutral-100 text-xs font-bold mb-2";
const inputStyle =
  "border-2 border-gray-400 px-3 py-3 placeholder-gray-300 text-neutral-100 font-bold bg-lbw-secondary-lighter rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150";
const buttonStyle =
  "bg-lbw-accent text-white active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";
