import React, { useEffect } from "react";
import { getUser, supabase } from "../../../utils/supabaseClient";
import SubscriptionInfo from "./SubscriptionInfo";
import { useUser } from "../../../contexts/User.context";
import { GreenNotification } from "../../ui/Notifications";

export const TBB4UserSettings = () => {
  const [passwords, setPasswords] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [newSettings, setNewSettings] = React.useState({
    tbb4_bot: false,
    tbb4_stake_size: 0,
    tbb4_stop_loss: 0,
    tbb4_target_profit: 0,
  });
  const [modal, setModal] = React.useState(false);

  const { user, updateUserSettings } = useUser();

  const onSaveHandler = () => {
    updateUserSettings(newSettings);
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, 2000);
  };

  const handlePasswordChange = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: passwords.newPassword,
    });

    if (error) {
      alert(error.message);
      return;
    }

    alert("Password changed successfully");
  };

  const betfairAccess = () => {
    localStorage.setItem("settingsId", user.us_id);
    const url =
      "https://identitysso.betfair.com/view/vendor-login?client_id=117531&response_type=code&redirect_uri=newjoin";
    window.open(url, "_blank").focus();
  };

  const signupBetfair = async () => {
    const url =
      "https://register.betfair.com.au/account/registration?clkID=660860_3C9FD1C7179140B683E834BA556D2CAA&rfr=660860&ttp=111&pid=4653327&bid=11207";
    window.open(url, "_blank").focus();
  };

  useEffect(() => {
    setNewSettings({
      tbb4_bot: user.tbb4_bot,
      tbb4_stake_size: user.tbb4_stake_size,
      tbb4_stop_loss: user.tbb4_stop_loss,
      tbb4_take_profit: user.tbb4_take_profit,
    });
  }, [user]);

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lbw-secondary border-0">
      <div className="rounded-t mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-neutral-100 text-xl font-cairoli font-bold">
            Settings
          </h6>
          {modal ? (
            <GreenNotification message="Your settings have been updated successfully" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="z-10 fixed right-0 top-0 mr-2 mt-2"></div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          {/* <hr className="mt-6 border-b-1 border-slate-300" /> */}
          {/* <SubscriptionInfo /> */}
          <hr className="mt-6 border-b-1 border-slate-300" />
          <h6 className="text-neutral-200 text-lg mt-3 mb-6 font-cairoli underline uppercase lg:mt-10">
            Betfair Account
          </h6>
          <div className="flex flex-wrap text-center">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                {user.betfair_access_token && user.betfair_refresh_token ? (
                  <>
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Account Status
                    </label>
                    <p className="text-lg bold text-emerald-500">Active</p>
                  </>
                ) : (
                  <>
                    <button
                      className=" bg-yellow-300 text-black  active:bg-yellow-200 text-sm font-bold uppercase 
                      px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
                      ease-linear transition-all duration-150"
                      type="button"
                      onClick={signupBetfair}
                    >
                      Sign up for Betfair Account
                    </button>
                    <p className="text-neutral-50">or</p>

                    <p
                      onClick={betfairAccess}
                      className="text-neutral-50 underline cursor-pointer"
                    >
                      Link existing account
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                ></label>
                {user.betfair_access_token && user.betfair_refresh_token ? (
                  <button
                    className=" bg-slate-500 text-white  active:bg-slate-600 text-sm font-bold uppercase 
                        px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
                        ease-linear transition-all duration-150"
                    type="button"
                  >
                    Revoke Betfair Account Access
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <hr className="mt-6 border-b-1 border-slate-300" />
          <h6 className="text-neutral-200 text-lg mt-3 mb-6 font-bold underline uppercase lg:mt-10">
            TBB4 Settings
          </h6>
          <div className="flex flex-wrap lg:mb-4 text-center">
            {user.tbb4_package === 3 ? (
              <></>
            ) : (
              <div className="w-1/2 lg:w-4/12 px-4 flex mb-12 lg:mb-4">
                <div className="relative w-full">
                  <label
                    className="block uppercase text-neutral-200 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bot On/Off
                  </label>
                  <div className="flex gap-x-4 pt-2 justify-center">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={newSettings.tbb4_bot}
                        onClick={(e) =>
                          setNewSettings({
                            ...newSettings,
                            tbb4_bot: e.target.checked,
                          })
                        }
                        className="sr-only peer"
                        readOnly
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      <span className="ml-3 uppercase text-neutral-200 text-xs font-bold">
                        LBW Bot
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="w-1/2 lg:w-4/12 px-4  mb-12 lg:mb-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-neutral-200 text-xs font-bold mb-2 underline"
                  data-tooltip-target="stake-size"
                >
                  Stake Size($AUD)
                </label>
                <div
                  id="stake-size"
                  role="tooltip"
                  className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                >
                  Size of your stake that is placed on an order. In Australian
                  Dollars($)
                  <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <input
                  value={newSettings.tbb4_stake_size}
                  onChange={(e) =>
                    setNewSettings({
                      ...newSettings,
                      tbb4_stake_size: e.target.value,
                    })
                  }
                  type="number"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-neutral-200 bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring max-sm:w-full w-1/4 text-center ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <div className="w-full flex max-sm:flex-row gap-x-4 lg:w-4/12 px-4">
              <div className="relative w-1/2 mb-3 justify-center">
                <label className="block uppercase text-neutral-200 text-xs font-bold mb-2">
                  Stop Loss Amount($AUD)
                </label>
                <input
                  type="number"
                  value={newSettings.tbb4_stop_loss}
                  onChange={(e) =>
                    setNewSettings({
                      ...newSettings,
                      tbb4_stop_loss: e.target.value,
                    })
                  }
                  className="border-0 py-3 placeholder-slate-300 text-neutral-200 bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring max-sm:w-full w-1/2 text-center ease-linear transition-all duration-150"
                  //   max="0"
                  //   min="-1000"
                />
                <div id="stopLossWarning" className="hidden bg-red-400 px-1">
                  <span>Stop loss must be less than or equal to zero</span>
                </div>
              </div>
              <div className="relative w-1/2 mb-3">
                <label className="block uppercase text-neutral-200 text-xs font-bold mb-2">
                  Target Profit Amount($AUD)
                </label>
                <input
                  type="number"
                  onChange={(e) => {
                    setNewSettings({
                      ...newSettings,
                      tbb4_take_profit: e.target.value,
                    });
                  }}
                  value={newSettings.tbb4_take_profit}
                  className="border-0 py-3 placeholder-neutral-200 text-neutral-200 bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring max-sm:w-full w-1/2 text-center ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </div>
          <div className="flex pt-4 justify-center">
            <div className="w-full lg:w-4/12 px-4">
              <button
                className="bg-lbw-accent text-neutral-900 hover:bg-[#c55b43] text-sm font-bold uppercase 
      px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
      ease-linear transition-all duration-150"
                type="button"
                onClick={onSaveHandler}
              >
                Save Changes
              </button>
            </div>
          </div>
          <hr className="mt-6 border-b-1 border-slate-300" />
          <h6 className="text-neutral-200 text-lg mt-3 mb-6 font-bold underline uppercase lg:mt-10">
            Change Password
          </h6>
          <div className="flex flex-wrap lg:mb-4">
            <div className="w-full lg:w-4/12 px-4 flex mb-12 lg:mb-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-neutral-200 text-xs font-bold mb-2"
                  data-tooltip-target="stake-size"
                >
                  New Password
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  onChange={(e) =>
                    setPasswords({ ...passwords, newPassword: e.target.value })
                  }
                  className="border-0 w-full px-3 py-3 placeholder-slate-300 text-neutral-200 bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4  mb-12 lg:mb-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-neutral-200 text-xs font-bold mb-2"
                  data-tooltip-target="stake-size"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  onChange={(e) =>
                    setPasswords({
                      ...passwords,
                      confirmPassword: e.target.value,
                    })
                  }
                  className="border-0 w-full px-3 py-3 placeholder-slate-300 text-neutral-200 bg-lbw-secondary-lighter rounded text-sm shadow focus:outline-none focus:ring text-left ease-linear transition-all duration-150"
                />
              </div>
            </div>

            <div className="w-full flex flex-wrap lg:w-4/12 px-4">
              <button
                className="bg-lbw-accent text-neutral-900 hover:bg-[#c55b43] text-sm font-bold uppercase 
                px-2 py-3 lg:py-0 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 lg:my-4 w-full 
                ease-linear transition-all duration-150"
                type="button"
                onClick={handlePasswordChange}
              >
                Save Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
