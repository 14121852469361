import React from "react";
import { supabase, invokeFunction } from "../../../utils/supabaseClient";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51O2o8TI9dTh7xQAWFaVzT6ZrKT8YGLkpbUi17KAWzHtZB3W5dOM7wLu1RpvzBxj7NcEEg2wCbTduKXNB0ipYeMSY00MsM6bDs7"
);

const SubscriptionInfo = () => {
  const [subscription, setSubscription] = React.useState(null);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };

  const fetchStripeInfo = async () => {
    const email = "j.schrank@bigpond.com";
    const customerRequest = await invokeFunction("stripeGetCustomer", {
      email: email,
    });
    let customer = customerRequest.data.data[0];
    console.log("customer", customer);
  };

  React.useEffect(() => {
    fetchStripeInfo();
  }, []);
  return (
    <>
      <h6 className="text-neutral-200 text-lg mt-3 mb-6 font-cairoli underline uppercase lg:mt-10">
        Your Subscription
      </h6>
      <div className="flex flex-row justify-evenly text-center">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-slate-600 text-sm font-bold mb-2"
              htmlFor="grid-password"
            >
              Current Plan
            </label>

            <p className="text-lg bold font-cairoli uppercase text-lbw-accent">
              Captains Lounge
            </p>
          </div>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-slate-600 text-sm font-bold mb-2"
              htmlFor="grid-password"
            >
              Card Details
            </label>

            <p className="text-lg bold font-cairoli uppercase text-lbw-accent">
              **** 4502
            </p>
          </div>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative w-full mb-3">
            <button
              className="bg-lbw-accent text-neutral-900 hover:bg-[#c55b43] text-sm font-bold uppercase 
      px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full 
      ease-linear transition-all duration-150"
              type="button"
            >
              Add Payment/Direct Debit
            </button>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default SubscriptionInfo;
