import React from "react";

const BaseCard = ({ children, divId }) => {
  return (
    <div
      id={divId}
      className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-lbw-secondary border-0 px-2 lg:px-6 py-2 lg:py-6"
    >
      {children}
    </div>
  );
};

export default BaseCard;
