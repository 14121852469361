import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import "flowbite";
import { PrivateRoute } from "./components/routes/PrivateRoute";
import { Login } from "./views/auth/Login";
import { RedirectBetfair } from "./views/RedirectBetfair";
import DashboardLayout from "./layouts/DashboardLayout";
// import Dashboard from "./views/tbb3/Dashboard";
// import Settings from "./views/tbb3/Settings";
// import UploadSheet from "./views/admin/UploadSheet";
// import { BotSettings } from "./views/admin/BotSettings";
// import { TBB4Settings } from "./views/admin/TBB4Settings";
// import { ManagerUsers } from "./views/admin/ManageUsers";
// import { EditSheet } from "./views/admin/EditSheet";
// import { Results } from "./views/tbb3/Results";
import { AdminRoute } from "./components/routes/AdminRoute";
import TBB4AdminDashboard from "./views/admin/Dashboard";
import { UserSettings } from "./views/settings/UserSettings";
import Dashboard from "./views/members/Dashboard";
import UserManagement from "./views/admin/UserManagement";
import DevelopmentBots from "./views/admin/DevelopmentBots";
import DevBotResults from "./views/admin/DevBotResults";
import StarterPackageSettings from "./views/admin/StarterPackageSettings";
import UserResults from "./views/members/UserResults";
import CaptainsLounge from "./views/members/CaptainsLounge";
import BackTesting from "./views/admin/BackTesting";
import RaceResults from "./views/members/RaceResults";
import { useUser } from "./contexts/User.context";
import { UserProvider } from "./contexts/User.context";
import { ForgotPassword } from "./views/auth/ForgotPassword";
import { CaptainsLoungeProvider } from "./contexts/CaptainsLounge.context";

function App() {
  const { user } = useUser();
  useEffect(() => {
    console.log("user", user);
  }, [user]);
  return (
    <UserProvider>
      {/* <CaptainsLoungeProvider> */}
      <Router>
        <Routes>
          {/* ALL ROUTES THAT REQUIRE AUTH TO ACCESS */}
          <Route element={<PrivateRoute />}>
            {/* TBB3 */}
            {/* <Route path="/tbb3" element={<DashboardLayout />}>
            <Route path="/tbb3/dashboard" exact element={<Dashboard />} />
            <Route
              path="/tbb3/dashboard/settings"
              exact
              element={<Settings />}
            />
            <Route path="/tbb3/dashboard/results" exact element={<Results />} />
          </Route> */}

            <Route path="/captains-lounge" element={<DashboardLayout />}>
              <Route
                path="/captains-lounge/strategy-management"
                exact
                element={<CaptainsLounge />}
              />
            </Route>

            {/* TBB4 */}
            <Route path="/tbb4" element={<DashboardLayout />}>
              {/* ADMIN ROUTES */}
              {/* <Route element={<AdminRoute admin={user?.admin} />}> */}
              <Route
                path="/tbb4/admin-dashboard"
                exact
                element={<TBB4AdminDashboard />}
              />
              <Route
                path="/tbb4/starter-package-settings"
                exact
                element={<StarterPackageSettings />}
              />
              <Route
                path="/tbb4/user-management"
                exact
                element={<UserManagement />}
              />
              <Route
                path="/tbb4/development-bots"
                exact
                element={<DevelopmentBots />}
              />
              <Route
                path="/tbb4/development-bots-results"
                exact
                element={<DevBotResults />}
              />
              <Route
                path="/tbb4/back-testing-simulator"
                exact
                element={<BackTesting />}
              />
              <Route
                path="/tbb4/race-results"
                exact
                element={<RaceResults />}
              />
              {/* </Route> */}
              {/* WELCOME PACKAGE */}
              <Route path="/tbb4/dashboard" exact element={<Dashboard />} />
              <Route path="/tbb4/results" exact element={<UserResults />} />
              <Route
                path="/tbb4/dashboard/settings"
                exact
                element={<UserSettings />}
              />
              {/* <Route path="/tbb4/dashboard/results" exact element={<Results />} /> */}
            </Route>

            {/* ADMIN */}
            <Route element={<AdminRoute />}>
              {/* <Route path="/admin" element={<DashboardLayout />}>
              <Route path="/admin/upload-sheet" element={<UploadSheet />} />
              <Route path="/admin/edit-sheet" element={<EditSheet />} />
              <Route path="/admin/bot-settings" element={<BotSettings />} />
              <Route path="/admin/tbb4-settings" element={<TBB4Settings />} />
              <Route path="/admin/manage-users" element={<ManagerUsers />} />
            </Route> */}
            </Route>
            <Route path="/newjoin" element={<RedirectBetfair />} />
          </Route>

          {/* ROUTES THAT DONT REQUIRE AUTH */}
          <Route path="/login" element={<Login />} />
          <Route path="/login/forgot-password" element={<ForgotPassword />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
      {/* </CaptainsLoungeProvider> */}
    </UserProvider>
  );
}

export default App;
