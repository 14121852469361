import React, { useState } from "react";
import { useUser } from "../../../contexts/User.context";
import { supabase } from "../../../utils/supabaseClient";

const ResultsTable = ({ results }) => {
  const [filteredResults, setFilteredResults] = useState([]);

  const { user } = useUser();

  const reduceResultsToFirstFive = () => {
    const filtered = results.filter((r) => r.profit !== 0).reverse();
    const reduced = filtered.slice(0, 5);
    setFilteredResults(reduced);
  };

  React.useEffect(() => {
    if (results) {
      reduceResultsToFirstFive();
    }
  }, [results]);
  return (
    <>
      <div className="py-2  space-y-4 rounded-md">
        <div className="flex flex-wrap w-full">
          <h2 className="text-neutral-100 text-2xl text-left font-cairoli font-bold ">
            Recent Results
          </h2>
          <div className="w-full">
            {filteredResults?.length > 0 ? (
              <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th className="  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Race
                    </th>
                    <th className="  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Selection
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Side
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-right">
                      Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResults.map((r) => (
                    <tr
                      className={`[&>td]:py-3 font-bold text-neutral-100 border-b-2 border-solid border-slate-400`}
                    >
                      <td
                        className={` border-t-0 align-middle border-l-0 border-r-0 text-xs lg:whitespace-nowrap p-4 text-left`}
                      >
                        {r.race_name}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {r.selection_name}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {r.side}
                      </td>
                      <td
                        className={`align-middle text-xs lg:whitespace-nowrap text-right ${
                          r.profit > 0
                            ? "text-green-500"
                            : !r.profit
                            ? ""
                            : "text-red-500"
                        }`}
                      >
                        {r.profit ? `$${r.profit.toFixed(2)}` : "PENDING"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Race
                    </th>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Selection
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Side
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={`[&>td]:py-2 font-bold text-neutral-100 border-b-2 border-solid border-slate-400`}
                  ></tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultsTable;
