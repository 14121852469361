import React from "react";
import axios from "axios";
import moment from "moment";
import { supabase } from "../../../utils/supabaseClient";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "../../ui/Icon";
export const UpcomingRaces = () => {
  const [marketData, setMarketData] = React.useState([]);

  const excludeRaceHandler = async (id, excluded) => {
    console.log(id, excluded);
    const ex = excluded === "Exclude" ? true : false;
    const { data, error } = await supabase
      .from("betfair_market_data")
      .update({ tbb4_excluded: ex })
      .eq("id", id);

    racingData();
  };

  const racingData = async () => {
    const s = await supabase
      .from("tbb4_master_settings")
      .select("*")
      .eq("id", 1);
    const botSettings = s.data[0];
    let raceTypes = [
      botSettings.horse ? "Thoroughbred" : null,
      botSettings.greyhound ? "Greyhound" : null,
      botSettings.harness ? "Harness" : null,
    ].filter((rt) => rt !== null);
    const now = moment().utc().format();
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gt("market_start_time", now)
      .in("race_type", raceTypes)
      .order("market_start_time", { ascending: true });
    const data = rawData.data;

    // console.log(data);
    setMarketData(data);
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      racingData();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  //   // Checks to place a bet every 10 seconds
  //   React.useEffect(() => {
  //     const interval = setInterval(() => {
  //       const botStatus = localStorage.getItem("botStatus");
  //       if (botStatus === "true") {
  //         betHandler();
  //       }
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 rounded-md">
      <div className="flex flex-wrap w-full justify-center">
        <div className="w-full px-4 overflow-x-auto">
          {marketData?.length > 0 ? (
            <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Start Time
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                    Venue
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Race
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Time still Jump
                  </th>
                  <th className="max-sm:hidden  bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Type
                  </th>
                  <th className="max-sm:px-0 lg:px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Runners
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Exclude?
                  </th>
                </tr>
              </thead>
              <tbody>
                {marketData.map((item, index) => (
                  <tr
                    className={`border-b-2  font-bold ${
                      item.tbb4_excluded || item.venue_excluded
                        ? "bg-red-200 text-lbw-secondary"
                        : "text-neutral-100"
                    }`}
                    key={item.id}
                  >
                    <td className="hidden md:block border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {moment(item.market_start_time).format("h:mma")}
                    </td>
                    <td className=" border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                      {window.screen.width > 768 ? (
                        ""
                      ) : item.race_type === "Thoroughbred" ? (
                        <HorseIcon className="text-2xl font-thin mr-2" />
                      ) : item.race_type === "Greyhound" ? (
                        <GreyhoundIcon className="text-xl font-thin mr-2" />
                      ) : (
                        <HarnessIcon className="text-xl font-thin mr-2" />
                      )}
                      {item.venue}
                      {"  "}
                      {window.screen.width < 768
                        ? ` - ${item.market_name.split(" ")[0]}`
                        : ""}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.market_name}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {moment(item.market_start_time).fromNow()}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.race_type === "Thoroughbred" ? (
                        <HorseIcon className="text-2xl font-thin" />
                      ) : item.race_type === "Greyhound" ? (
                        <GreyhoundIcon className="text-xl font-thin" />
                      ) : (
                        <HarnessIcon className="text-xl font-thin" />
                      )}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.runner_count}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <button
                        onClick={() =>
                          item.venue_excluded
                            ? alert("Venue is excluded. Include Venue first")
                            : excludeRaceHandler(
                                item.id,
                                item.tbb4_excluded ? "Include" : "Exclude"
                              )
                        }
                        // disabled={item.venue_excluded}
                        id={item.id}
                        aria-details={
                          item.tbb4_excluded ? "Include" : "Exclude"
                        }
                        className={`${
                          item.venue_excluded
                            ? "bg-green-900"
                            : item.tbb4_excluded
                            ? "bg-green-900"
                            : "bg-red-900"
                        } text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                      >
                        {item.venue_excluded
                          ? "Include"
                          : item.tbb4_excluded
                          ? "Include"
                          : "Exclude"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
