import React from "react";
import moment from "moment";
import axios from "axios";
import { supabase, supabaseKey, getUser } from "../../../utils/supabaseClient";
import { useUser } from "../../../contexts/User.context";
import { GreyhoundIcon, HarnessIcon, HorseIcon } from "../../ui/Icon";
import { getUsersUpcomingRaces } from "../../../utils/user";
import CountdownText from "../../ui/CountdownText";

const UpcomingandResults = () => {
  const [marketData, setMarketData] = React.useState([]);
  const [unsettled, setUnsettled] = React.useState([]);

  const { user } = useUser();

  const getRunnerData = async (token, marketId, selectionId) => {
    let config = {
      url: "https://sb.laybackandwin.au/functions/v1/betfairBettingRequest",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + supabaseKey,
      },
      data: {
        accessToken: token,
        requestMethod: "listMarketCatalogue",
        betfairData: {
          filter: {
            marketIds: [marketId],
          },
          marketProjection: [
            "MARKET_DESCRIPTION",
            "RUNNER_DESCRIPTION",
            "RUNNER_METADATA",
          ],
          maxResults: 1,
        },
      },
    };
    let d = await axios(config);
    let market = d.data.result[0];
    let runners = d.data.result[0].runners;
    let runnerData = runners.filter(
      (item) => item.selectionId === selectionId
    )[0];
    // console.log(market);
    return {
      // venue: market.venue,
      marketName: market.marketName,
      runnerName: runnerData.runnerName,
      silkUrl: runnerData.metadata.COLOURS_FILENAME,
    };
  };

  const getUnsettled = async () => {
    let token = user.betfair_access_token;
    let config = {
      url: "https://sb.laybackandwin.au/functions/v1/betfairBettingRequest",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + supabaseKey,
      },
      data: {
        accessToken: token,
        requestMethod: "listCurrentOrders",
        betfairData: {
          orderBy: "BY_PLACE_TIME",
          customerStrategyRefs: ["TBB4-TEST"],
          orderProjection: "ALL",
        },
      },
    };
    let d = await axios(config);
    let currentOrders = d.data.result.currentOrders;
    //   console.log(currentOrders);
    var co = await Promise.all(
      currentOrders.map(async (item, index) => {
        const { data, error } = await supabase
          .from("betfair_market_data")
          .select("venue, market_id")
          .eq("market_id", item.marketId)
          .limit(1);

        const venue = data[0].venue;

        let runnerData = await getRunnerData(
          token,
          item.marketId,
          item.selectionId
        );
        return {
          ...item,
          selection_name: runnerData.runnerName,
          market_name: runnerData.marketName,
          venue: `${venue} - ${runnerData.marketName.split(" ")[0]}`,
          selection_silks_url: `https://content-cache.cdnppb.net/feeds_images/Horses/SilkColours/${runnerData.silkUrl}`,
        };
      })
    );

    //   let data = {};
    setUnsettled(co);

    // console.log(co);
  };

  const racingData = async () => {
    const data = await getUsersUpcomingRaces(user);
    setMarketData(data);
  };

  // Executed upon load
  React.useEffect(() => {
    racingData();
    getUnsettled();
  }, []);

  // Fetches race data every 60 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      racingData();
      getUnsettled();
    }, 12020);
    return () => clearInterval(interval);
  }, [marketData]);

  return (
    <>
      <div className="py-2  space-y-4 rounded-md">
        <div className="flex flex-wrap w-full">
          <h2 className="text-neutral-100 text-2xl text-left font-cairoli font-bold ">
            Current Orders
          </h2>
          <div className="w-full overflow-x-auto">
            {unsettled?.length > 0 ? (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Match Status
                    </th>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Side
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Selection
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Venue
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unsettled.map((r) => (
                    <tr
                      className={`[&>td]:py-2 font-bold text-neutral-100 border-b-2 border-solid border-slate-400`}
                    >
                      <td
                        className={`${
                          r.status === "EXECUTABLE"
                            ? "text-yellow-500"
                            : "text-green-500"
                        } border-t-0 align-middle border-l-0 border-r-0 text-xs lg:whitespace-nowrap p-4 text-left`}
                      >
                        {r.status === "EXECUTABLE" ? "Waiting" : "Matched"}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {r.side}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {r.selection_name}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {r.venue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Match Status
                    </th>
                    <th className="w-10  text-lbw-accent align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Side
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Selection
                    </th>
                    <th className=" text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Venue
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={`[&>td]:py-2 font-bold text-neutral-100 border-b-2 border-solid border-slate-400`}
                  ></tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4 pb-2 rounded-md mb-4">
        <div className="flex flex-wrap w-full">
          <h2 className="text-neutral-100 text-2xl font-cairoli font-bold ">
            Next Up
          </h2>
          <div className="w-full">
            {marketData?.length > 0 ? (
              <table className="items-center w-full bg-transparent border-collapse border-spacing-y-4">
                <thead>
                  <tr className=" border-b-2 border-solid border-slate-400">
                    <th className="  text-lbw-accent  align-middle py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Time til Jump
                    </th>
                    <th className=" text-lbw-accent  align-middle py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Venue
                    </th>
                    <th className="  text-lbw-accent  align-middle py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 lg:whitespace-nowrap font-semibold text-left">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {marketData.map((item, index) => (
                    <tr
                      className="[&>td]:py-2 font-bold text-neutral-100 border-b-2 border-solid border-slate-400 "
                      key={item.id}
                    >
                      <td
                        className={`${
                          item.redTime ? "text-red-500" : "text-neutral-100"
                        } align-middle text-xs lg:whitespace-nowrap text-left`}
                      >
                        <CountdownText jumpTime={item.market_start_time} />
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {item.venue}
                      </td>
                      <td className="align-middle text-xs lg:whitespace-nowrap">
                        {item.race_type === "Thoroughbred" ? (
                          <HorseIcon className="text-xl font-thin" />
                        ) : item.race_type === "Greyhound" ? (
                          <GreyhoundIcon className="text-xl font-thin" />
                        ) : (
                          <HarnessIcon className="text-xl font-thin" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingandResults;
