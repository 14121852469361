import React, { useState, useEffect } from "react";
import { GiMoneyStack } from "react-icons/gi";
import { BsCheck2All } from "react-icons/bs";
import { useUser } from "../../../contexts/User.context";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
  TrophyIcon,
  SecondPlaceIcon,
} from "../../ui/Icon";
const DailyMetrics = ({ results }) => {
  const [metrics, setMetrics] = useState({
    backs: 0,
    lays: 0,
    profit: 0,
    matchRate: 0,
  });
  const { user } = useUser();
  const handleResults = () => {
    const backs = results?.filter((item) => item.side === "BACK").length
      ? results.filter((item) => item.side === "BACK").length
      : 0;
    const lays = results?.filter((item) => item.side === "LAY").length
      ? results.filter((item) => item.side === "LAY").length
      : 0;
    const profit = results?.reduce((a, b) => a + b.profit, 0);
    const nullCount =
      results?.filter((item) => item.profit === null).length ?? 0;
    let matchRate =
      ((results?.length - nullCount) / results?.length) * 100
        ? ((results?.length - nullCount) / results?.length) * 100
        : 0;
    if (isNaN(matchRate)) {
      matchRate = 0;
    }

    setMetrics({
      backs: backs ? backs : 0,
      lays: lays ? lays : 0,
      profit: profit ? profit : 0,
      matchRate: matchRate ? matchRate : 0,
    });
  };

  useEffect(() => {
    handleResults();
  }, [results]);
  return (
    <div className="flex flex-wrap ">
      <div className="w-full flex max-sm:flex-wrap lg:flex-row lg:gap-x-6 gap-y-4 text-center">
        <div className="max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4 my-auto ">
          <MetricCard
            title="Profit"
            icon={<GiMoneyStack className="text-[50px] text-lbw-accent" />}
            value={metrics.profit}
            goal={5 * user.tbb4_stake_size}
            progress
            dollar
          />
        </div>
        <div className="w-1/2 lg:w-1/4 space-y-4">
          <MetricCard
            title="Match Rate"
            icon={<BsCheck2All className="text-[50px] text-lbw-accent" />}
            value={metrics.matchRate}
            goal={100}
            progress
            percent
            matchRate
          />
        </div>
        <div className="flex max-sm:flex-row max-sm:space-x-2 w-1/2 lg:w-1/4 space-y-4">
          <MetricCard
            title="Backs"
            icon={<TrophyIcon className="text-[50px] text-lbw-accent" />}
            value={metrics.backs}
            goal={100}
            // progress
          />
        </div>
        <div className=" flex w-1/2 lg:w-1/4 space-y-4">
          <MetricCard
            title="Lays"
            icon={<SecondPlaceIcon className="text-[50px] text-lbw-accent" />}
            value={metrics.lays}
            goal={100}
            // progress
          />
        </div>
      </div>
    </div>
  );
};

const MetricCard = ({
  title,
  icon,
  value,
  goal,
  progress,
  dollar,
  percent,
  matchRate,
}) => {
  const { user } = useUser();
  const valueActual = value ? value : 0;
  const progressValue =
    value !== 0
      ? (parseInt(valueActual) / (matchRate ? 100 : user.tbb4_take_profit)) *
        100
      : 0;
  // console.log(progressValue);

  return (
    <div className="container px-10 bg-lbw-secondary p-4 lg:rounded-lg py-auto">
      <div className="w-full pb-2">
        <p className=" text-neutral-100 font-cairoli text-sm">{title}</p>
      </div>
      <div className="flex flex-row gap-x-12 items-center">
        <div className="w-1/4 max-sm:hidden">{icon}</div>
        <div className="max-sm:w-full w-3/4">
          <p className=" text-neutral-100 max-sm:text-center text-left font-cairoli text-2xl">
            {dollar ? "$" : ""}
            {dollar || percent ? valueActual.toFixed(2) : valueActual}
            {percent ? "%" : ""}
          </p>
        </div>
      </div>
      {progress ? (
        <div className="w-full pt-4">
          {/* <p className="text-right text-neutral-400 text-xs">what is this?</p> */}
          <div class="mb-5 h-2 rounded-full bg-gray-200">
            <div
              class={`h-2 rounded-full ${
                progressValue >= user.tbb4_take_profit
                  ? "bg-green-400"
                  : "bg-lbw-accent"
              }`}
              style={{ width: `${progressValue > 100 ? 100 : progressValue}%` }}
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DailyMetrics;
