import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabaseClient";
import moment from "moment";
// import stringify from "csv-stringify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DevBotResultsTable = ({ bot_id, dates }) => {
  const [botResults, setBotResults] = useState([]);
  const [botName, setBotName] = useState("");
  const [filterUnmatched, setFilterUnmatched] = useState(true);
  const [orderCount, setOrderCount] = useState(0);
  const [matchedCount, setMatchedCount] = useState(0);
  const [layCount, setLayCount] = useState(0);
  const [backCount, setBackCount] = useState(0);
  const [winCount, setWinCount] = useState(0);
  const [layProfit, setLayProfit] = useState(0);
  const [backProfit, setBackProfit] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  const getBotResults = async () => {
    const { data, error } = await supabase
      .from("dev_bot_full_results")
      .select("*")
      .eq("bot_id", bot_id)
      .gte(
        "order_placed_time",
        moment(dates[0]).format("YYYY-MM-DDT00:00:00.000+10:00:00")
      )
      .lte(
        "order_placed_time",
        moment(dates[1]).format("YYYY-MM-DDT23:59:59.000+10:00:00")
      )
      .order("order_placed_time", { ascending: false });
    console.log(data);
    if (error) {
      console.log(error);
    } else {
      if (filterUnmatched) {
        setBotResults(data.filter((item) => item.event_name !== null));
      } else {
        setBotResults(data);
      }
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const d = botResults.map((item) => ({
      Date: moment(item.order_placed_time).format("ddd DD MMM"),
      Race: item.race_name,
      Selection: item.selection_name,
      Side: item.side,
      Percentage: item.percentage,
      Result: item.result,
      Profit: item.profit,
      Stake: item.order_stake,
      Price: item.order_price,
    }));
    const ws = XLSX.utils.json_to_sheet(d);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${botName} - ${moment(dates[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dates[1]).format("DD MMM YYYY")} ${fileExtension}`;
    FileSaver.saveAs(data, fileName);
  };

  useEffect(() => {
    getBotResults();
    const d = document.getElementById("bot-name");
    const current = d.options[d.selectedIndex].text;
    setBotName(current);
  }, [bot_id, dates]);

  const refreshData = () => {
    getBotResults();
  };

  const filterUnmatchedRaces = (filter) => {
    setFilterUnmatched(!filterUnmatched);

    if (filterUnmatched) {
      setBotResults(botResults.filter((item) => item.event_name !== null));
    } else {
      getBotResults();
    }
    setFilterUnmatched(!filterUnmatched);
  };

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     getBotResults();
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [botResults]);

  useEffect(() => {
    if (botResults.length > 0) {
      setOrderCount(botResults.length);
      setLayCount(
        botResults.filter(
          (item) => item.side === "LAY" && item.event_name !== null
        ).length
      );
      setMatchedCount(
        botResults.filter((item) => item.event_name !== null).length
      );
      setBackCount(
        botResults.filter(
          (item) => item.side === "BACK" && item.event_name !== null
        ).length
      );
      setWinCount(
        botResults.filter(
          (item) => item.result === "WON" && item.event_name !== null
        ).length
      );
      setLayProfit(
        botResults
          .filter((item) => item.side === "LAY")
          .reduce((a, b) => a + b.profit, 0)
      );
      setBackProfit(
        botResults
          .filter((item) => item.side === "BACK")
          .reduce((a, b) => a + b.profit, 0)
      );
      setTotalProfit(botResults.reduce((a, b) => a + b.profit, 0));
    }
  }, [botResults]);

  return (
    <div className=" bg-lbw-secondary space-y-4 rounded-md">
      {botResults?.length > 0 ? (
        <div className="py-4 flex flex-wrap w-full justify-center">
          <div className="w-full px-4 max-sm:py-0 py-4">
            <div className="flex text-center max-sm:flex-wrap md:flex-row w-full max-sm:justify-evenly justify-between">
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Orders</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  {orderCount}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Matched</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  {matchedCount} (
                  {((matchedCount / orderCount) * 100).toFixed(2)}
                  %)
                </p>
              </div>
              <div className="max-sm:hidden lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Lays</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  {layCount} ({((layCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
              <div className="max-sm:hidden lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Backs</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  {backCount} ({((backCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Wins</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  {winCount} ({((winCount / matchedCount) * 100).toFixed(2)}%)
                </p>
              </div>
            </div>
            <div className="flex text-center max-sm:flex-wrap md:flex-row w-full justify-center">
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Lay Profit</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  ${layProfit.toFixed(2)}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Back Profit</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  ${backProfit.toFixed(2)}
                </p>
              </div>
              <div className="w-4/12 lg:w-2/12 flex flex-col mt-6 mb-2 xl:mb-0 align-middle">
                <p className="text-neutral-100 my-auto">Total Profit</p>
                <p className="max-sm:text-lg text-3xl text-neutral-100 font-bold">
                  ${totalProfit.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full flex max-sm:flex-col-reverse max-sm:gap-y-4 flex-row
           px-4 py-4"
          >
            <div className="max-sm:w-full w-1/6">
              <button
                className="bg-lbw-accent float-right mt-auto text-white max-sm:w-full  active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={refreshData}
              >
                Refresh Results
              </button>
            </div>
            <div className="max-sm:w-full w-1/6">
              <button
                className="bg-lbw-accent float-right mt-auto max-sm:w-full  text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={filterUnmatchedRaces}
              >
                {filterUnmatched ? "Hide Unmatched" : "Show Unmatched"}
              </button>
            </div>
            <div className="max-sm:w-full w-4/6 float-right">
              <button
                className="bg-lbw-accent max-sm:w-full  float-right mt-auto text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={exportToCSV}
              >
                Download Excel
              </button>
            </div>
          </div>
          <div className="w-full px-4 overflow-x-auto">
            {/* <button
              className="bg-green-900 text-neutral-100 active:bg-green-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
            >
              Export to CSV
            </button> */}
            <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    #
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Date
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Race
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Selection
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Side
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Percentage
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Price
                  </th>

                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Profit
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Stake
                  </th>
                </tr>
              </thead>
              <tbody>
                {botResults.map((item, index) => (
                  <tr
                    className={`border-b-2  font-bold ${
                      item.tbb4_excluded
                        ? "bg-red-200 text-lbw-secondary"
                        : "text-neutral-100"
                    }`}
                    key={item.id}
                  >
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {index + 1}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-left border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {moment(item.order_placed_time).format("ddd DD MMM")}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.race_name}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.selection_name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.side}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.percentage ? item.percentage.toFixed(2) + "%" : ""}
                    </td>
                    <td
                      className={`${
                        item.order_price === 5
                          ? "text-orange-500"
                          : "text-white"
                      } border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4`}
                    >
                      ${item.order_price.toFixed(2)}
                    </td>
                    <td
                      className={`${
                        item.profit > 0
                          ? "text-green-500"
                          : !item.profit
                          ? "text-white"
                          : "text-red-500"
                      } border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4`}
                    >
                      {item.profit ? "$" + item.profit.toFixed(2) : "UNMATCHED"}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ${item.order_stake.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DevBotResultsTable;
