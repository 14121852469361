import React, { useEffect, useState } from "react";
import LineChartResults from "../../components/Members/Dashboard/LineChartResults";
import BaseCard from "../../components/ui/BaseCard";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";
import BarGraphBackLay from "../../components/Members/Dashboard/BarGraphBackLay";
import DailyMetrics from "../../components/Members/Dashboard/DailyMetrics";
import UpcomingandResults from "../../components/Members/Dashboard/UpcomingandResults";
import QuickSettings from "../../components/Members/Dashboard/QuickSettings";
import { useUser } from "../../contexts/User.context";
import ResultsTable from "../../components/Members/Dashboard/ResultsTable";

export default function Dashboard() {
  const [dateRange, setDateRange] = useState(1); // [7, 14, 30
  const [results, setResults] = useState(null);
  const [showQuickSettingsModal, setShowQuickSettingsModal] = useState(false);
  const { user } = useUser();

  const getResults = async () => {
    const fromDate = moment()
      .subtract(dateRange - 1, "days")
      .format("YYYY-MM-DDT00:00:00.000+10:00:00");
    const toDate = moment().format("YYYY-MM-DDT23:59:59.000");
    const { data, error } = await supabase
      .from("order_results_details")
      .select("*")
      // .neq("profit", 0)
      .eq("auth_id", user.auth_id)
      .gte("order_placed_time", fromDate)
      .lte("order_placed_time", toDate)
      .order("order_placed_time", { ascending: true });

    if (data) {
      let runningTotal = 0;
      data.forEach((item) => {
        runningTotal = runningTotal + item.profit;
        item.running_profit = runningTotal;
        item.date = moment(item.order_placed_time).format("DD-MM-YYYY");
      });
      // console.log(data);
      setResults(data);
    } else if (error) {
      console.log(error);
    }
  };

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
  };

  useEffect(() => {
    getResults();
  }, []);

  useEffect(() => {
    getResults();
  }, [dateRange]);

  useEffect(() => {
    setTimeout(() => {
      if (dateRange === 1) {
        getResults();
      }
    }, 60000);
  }, [results]);

  return (
    <div className="space-y-6">
      <div className="flex max-sm:flex-col lg:flex-row justify-between gap-x-2 pt-12">
        <div className="w-full">
          <h2 className="text-neutral-100 text-3xl font-cairoli">DASHBOARD</h2>
        </div>
      </div>
      <div className="flex max-md:flex-col-reverse max-md:space-y-reverse max-md:space-y-4 lg:flex-row gap-x-6 pt-6">
        {/* <div className="md:hidden w-full lg:w-9/12 flex xl:mb-0 align-middle"></div> */}
        <div className="hidden md:w-9/12 md:flex xl:mb-0 align-middle">
          {/* <p className="text-neutral-100 my-auto">Date Range </p> */}
          <QuickSettings />
        </div>
        <div className="w-full lg:w-3/12 xl:mb-0 align-middle">
          {/* <p className="text-neutral-100 my-auto">Date Range </p> */}
          <select
            value={dateRange}
            onChange={handleDateRangeChange}
            className="bg-lbw-secondary text-neutral-100 border-0 px-3 max-sm:py-4 w-full h-full rounded text-xl shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
          >
            <option value={1}>Today</option>
            <option value={2}>Yesterday</option>
            <option value={7}>Last 7 Days</option>
            <option value={14}>Last 2 Weeks</option>
            <option value={30}>Last Month</option>
          </select>
        </div>
      </div>
      <div className="flex max-md:flex-col lg:flex-row">
        <div className="w-full mt-6">
          {/* <BaseCard> */}
          <DailyMetrics results={results} />
          {/* </BaseCard> */}
        </div>
      </div>
      <div className="md:hidden mb-0 align-middle ">
        <button
          type="button"
          onClick={() => setShowQuickSettingsModal(!showQuickSettingsModal)}
          className="bg-lbw-accent text-neutral-100 border-0 px-3 py-4 w-full h-full rounded text-xl shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
        >
          {showQuickSettingsModal ? "Close" : "Quick Settings"}
        </button>
      </div>
      <div
        className={`${
          showQuickSettingsModal === true ? "flex flex-row" : "hidden"
        } md:hidden xl:mb-0 align-middle`}
      >
        <QuickSettings />
      </div>
      <div className="flex max-md:flex-col-reverse lg:flex-row gap-x-6">
        <div className="w-full xl:w-6/12 my-6 xl:mb-0">
          <BaseCard>
            {results ? <LineChartResults results={results} /> : null}
          </BaseCard>
        </div>
        <div className="w-full xl:w-6/12 my-6 xl:mb-0">
          <BaseCard divId="upcoming-results">
            <UpcomingandResults />
          </BaseCard>
        </div>
      </div>
      <div className="flex max-md:flex-col-reverse lg:flex-row gap-x-6">
        <div className="w-full xl:w-6/12 mb-12 xl:mb-0">
          <BaseCard>
            <BarGraphBackLay results={results} />
          </BaseCard>
        </div>
        <div className="w-full xl:w-6/12 mb-12 xl:mb-0">
          <BaseCard>
            <ResultsTable results={results} />
          </BaseCard>
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 xl:mb-0"></div>
        <div className="w-full xl:w-4/12"></div>
      </div>
    </div>
  );
}

const QuickSettingsModal = ({ show }) => (
  <div
    className={`${
      show ? "relative" : "hidden"
    } modal fade fixed top-0 left-0 z-50 w-full h-full outline-none overflow-x-hidden overflow-y-auto`}
  >
    <div class="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
      <h5
        class="text-xl font-medium leading-normal text-gray-800"
        id="exampleModalLabel"
      >
        QuickSettings
      </h5>

      <button
        type="button"
        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
);
