import React, { useState, useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

const BarGraphBackLay = ({ results }) => {
  const [cleanedData, setCleanedData] = useState(null);

  const cleanResults = () => {
    let venueList = [];
    results.forEach((item) => {
      if (!venueList.includes(item.venue)) {
        venueList.push(item.venue);
      }
    });

    let data = [];

    venueList.forEach((venue) => {
      let layprofit = results
        .filter((item) => item.venue === venue && item.side === "LAY")
        .map((item) => item.profit)
        .reduce((a, b) => a + b, 0);
      let layCount = results.filter(
        (item) => item.venue === venue && item.side === "LAY"
      ).length;
      let backprofit = results
        .filter((item) => item.venue === venue && item.side === "BACK")
        .map((item) => item.profit)
        .reduce((a, b) => a + b, 0);
      let backCount = results.filter(
        (item) => item.venue === venue && item.side === "BACK"
      ).length;
      let backWinCount = results.filter(
        (item) =>
          item.venue === venue && item.side === "BACK" && item.profit > 0
      ).length;
      let layWinCount = results.filter(
        (item) => item.venue === venue && item.side === "LAY" && item.profit > 0
      ).length;
      data.push({
        name: venue,
        layPerc: layprofit / layCount,
        layCount: layCount,
        lay: layprofit,
        backPerc: backprofit / backCount,
        backCount: backCount,
        back: backprofit,
        backWinPerc: backCount === 0 ? 0 : (backWinCount / backCount) * 100,
        layWinPerc: layCount === 0 ? 0 : (layWinCount / layCount) * 100,
      });
    });

    return data;
  };

  useEffect(() => {
    // console.log(results);
    if (results) {
      // console.log(results);
      let d = cleanResults();
      // console.log(d);
      setCleanedData(d);
    }
  }, [results]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={cleanedData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis angle={35} domain={[0, 100]} />
        <Radar
          name="Lay"
          dataKey="layWinPerc"
          stroke="#0d85f2"
          fill="#0d85f2"
          fillOpacity={0.6}
        />
        <Radar
          name="Back"
          dataKey="backWinPerc"
          stroke="#1BA55E"
          fill="#1BA55E"
          fillOpacity={0.6}
        />
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default BarGraphBackLay;
