import React, { useState, useEffect } from "react";
import moment from "moment";
import { supabase, supabaseKey } from "../../../utils/supabaseClient";
import { HorseIcon, GreyhoundIcon, HarnessIcon } from "../../ui/Icon";
export const UpcomingVenues = () => {
  const [venues, setVenues] = useState([]);

  const getVenues = async () => {
    const now = moment().utc().format();
    const { data, error } = await supabase
      .from("betfair_market_data")
      .select("*")
      .gte("market_start_time", now)
      .order("market_start_time", { ascending: true });

    const uniqueVenues = data.filter((race, index, self) => {
      return index === self.findIndex((t) => t.venue === race.venue);
    });

    setVenues(uniqueVenues);
  };

  const venueExclusionHandler = async (venueName, action) => {
    const today = moment().format("YYYY-MM-DD");
    const exclude = action === "Exclude" ? true : false;
    const { data, error } = await supabase
      .from("betfair_market_data")
      .update({ venue_excluded: exclude })
      .eq("venue", venueName)
      .eq("event_date", today);

    if (error) {
      alert("Something went wrong, please try again.");
      getVenues();
    } else {
      // alert(`${venueName} has been ${action}d`);
      getVenues();
    }
  };

  useEffect(() => {
    getVenues();
  }, []);

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 rounded-md">
      {/* <h2 className="text-center text-white text-3xl font-bold">
        WORK IN PROGRESS
      </h2> */}
      <div className="flex flex-wrap w-full justify-center">
        <div className="w-full px-4 overflow-x-auto">
          {venues?.length > 0 ? (
            <table className="items-center w-fit lg:w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Meeting Date
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Venue
                  </th>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Type
                  </th>
                  <th className="px-6 bg-lbw-secondary text-neutral-100 align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Exclude?
                  </th>
                </tr>
              </thead>
              <tbody>
                {venues.map((item, index) => (
                  <tr
                    className={`border-b-2  font-bold ${
                      item.venue_excluded
                        ? "bg-red-200 text-lbw-secondary"
                        : "text-neutral-100"
                    }`}
                    key={item.id}
                  >
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {moment(item.event_date).format("ddd MMM Do")}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {window.screen.width > 768 ? (
                        ""
                      ) : item.race_type === "Thoroughbred" ? (
                        <HorseIcon className="text-2xl font-thin mr-2" />
                      ) : item.race_type === "Greyhound" ? (
                        <GreyhoundIcon className="text-xl font-thin mr-2" />
                      ) : (
                        <HarnessIcon className="text-xl font-thin mr-2" />
                      )}
                      {item.venue}
                    </td>
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.race_type}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <button
                        onClick={() =>
                          venueExclusionHandler(
                            item.venue,
                            item.venue_excluded ? "Include" : "Exclude"
                          )
                        }
                        id={item.id}
                        aria-details={
                          item.venue_excluded ? "Include" : "Exclude"
                        }
                        className={`${
                          item.venue_excluded ? "bg-green-900" : "bg-red-900"
                        } text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                      >
                        {item.venue_excluded ? "Include" : "Exclude"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
