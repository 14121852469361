import React from "react";
import { TBB4UserSettings } from "../../components/Members/Settings/TBB4UserSettings";
export const UserSettings = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full max-sm:pt-6 pt-16 max-sm:px-0 px-4">
        <TBB4UserSettings />
      </div>
    </div>
  );
};
