import { supabase } from "./supabaseClient";
import moment from "moment";

export const updateUser = (user) => {
  // console.log(user);
};

// This will get the users upcoming races that matches there subsccription type
export const getUsersUpcomingRaces = async (user) => {
  let tbb4_setting_id = 1;
  // Assign package setting id
  if (user.tbb4_package === 1) {
    tbb4_setting_id = 2;
  } else if (user.tbb4_package === 2) {
    tbb4_setting_id = 1;
  }

  // Find what races are available
  // dependant on the package settings
  if (user.tbb4_package !== 3 && user.tbb4_package !== 4) {
    const s = await supabase
      .from("tbb4_master_settings")
      .select("*")
      .eq("id", tbb4_setting_id);
    const botSettings = s.data[0];
    // Filter race types
    let raceTypes = [
      botSettings.horse ? "Thoroughbred" : null,
      botSettings.greyhound ? "Greyhound" : null,
      botSettings.harness ? "Harness" : null,
    ].filter((rt) => rt !== null);

    // Get Racing data
    const now = moment().utc().format();
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gt("market_start_time", now)
      .in("race_type", raceTypes)
      .eq("tbb4_excluded", false)
      .order("market_start_time", { ascending: true })
      .limit(4);
    const data = rawData.data;

    let updatedMarketData = data.map((item) => {
      let now = moment().utc();
      let raceTime = moment(item.market_start_time).utc();
      let diff = raceTime.diff(now, "seconds");
      let minDiff = raceTime.diff(now, "minutes");
      if (diff < 60) {
        return {
          ...item,
          timeTilJump: `${diff}s`,
          redTime: true,
          diff: diff,
        };
      } else if (diff > 60 && diff < 300) {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: true,
          diff: diff,
        };
      } else {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: false,
          diff: diff,
        };
      }
    });

    // console.log(data);
    return updatedMarketData;
  } else {
    const { data, error } = await supabase
      .from("cl_user_strategies")
      .select("*")
      .eq("user_id", user.auth_id);

    const strats = data;
    // console.log(settings);

    let raceTypes = [
      strats.filter((s) => s.race_type === "Thoroughbred" && s.active === true)
        .length !== 0
        ? "Thoroughbred"
        : null,
      strats.filter((s) => s.race_type === "Greyhound" && s.active === true)
        .length !== 0
        ? "Greyhound"
        : null,
      strats.filter((s) => s.race_type === "Harness" && s.active === true)
        .length !== 0
        ? "Harness"
        : null,
    ].filter((rt) => rt !== null);
    const twoDaysAgo = moment().subtract(2, "days").utc().format();
    const now = moment().utc().format();
    const overrides = await supabase
      .from("cl_race_and_venue_exclusion")
      .select("*")
      .gte("created_at", twoDaysAgo);
    const excludedRaces = overrides.data
      .filter((item) => item.exclude === true)
      .map((item) => item.market_id);
    const rawData = await supabase
      .from("betfair_market_data")
      .select("*")
      .gte("market_start_time", now)
      .in("race_type", raceTypes)
      .order("market_start_time", { ascending: true })
      .limit(5);
    // console.log(rawData);
    const races = rawData.data.filter(
      (item) => !excludedRaces.includes(item.market_id)
    );
    // console.log(races);
    let updatedMarketData = races.map((item) => {
      let now = moment().utc();
      let raceTime = moment(item.market_start_time).utc();
      let diff = raceTime.diff(now, "seconds");
      let minDiff = raceTime.diff(now, "minutes");
      if (diff < 60) {
        return {
          ...item,
          timeTilJump: `${diff}s`,
          redTime: true,
          diff: diff,
        };
      } else if (diff > 60 && diff < 300) {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: true,
          diff: diff,
        };
      } else {
        return {
          ...item,
          timeTilJump: `${minDiff}m ${diff - minDiff * 60}s`,
          redTime: false,
          diff: diff,
        };
      }
    });

    return updatedMarketData;
  }
};
