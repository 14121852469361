import React from "react";
import { supabase } from "../../utils/supabaseClient";
import moment from "moment";

const UserTable = () => {
  const [users, setUsers] = React.useState([]);
  const [packages, setPackages] = React.useState([]);

  const getUsers = async () => {
    const { data, error } = await supabase
      .from("user_information_full")
      .select("*")
      .order("tbb4_bot", { ascending: false })
      .order("last_sign_in_at", { ascending: false });

    if (data) {
      setUsers(data);
    } else if (error) {
      console.log(error);
    }
  };

  const getTbb4Package = async () => {
    const { data, error } = await supabase.from("tbb4_packages").select("*");

    if (data) {
      setPackages(data);
    } else if (error) {
      console.log(error);
    }
  };

  // Package ID Guide
  // 1 = Starter
  // 2 = Intermediate
  // 3 = Captains Lounge
  // 4 = Full Free

  const handleCaptainsLoungeAdd = async (user) => {
    console.log(user);
    const { data, error } = await supabase
      .from("captains_lounge_full")
      .select("*")
      .eq("user_id", user.ud_id);

    if (error) {
      console.log(error);
    }

    if (data) {
      console.log(data);
      if (data.length === 0) {
        console.log("No Captains Lounge entry found, adding...");
        const { data, error } = await supabase
          .from("cl_user_bot_settings")
          .insert([{ auth_id: user.auth_id }]);

        if (data) {
          console.log(data);
        } else if (error) {
          console.log(error);
        }
      }
    } else if (error) {
      console.log(error);
      alert("Error adding user to Captains Lounge");
    }
  };

  const handlePackageChange = async (e, index, id) => {
    const packageId = e.target.value;
    const { data, error } = await supabase
      .from("user_subscription")
      .update({ tbb4_package: e.target.value })
      .select("*")
      .eq("id", id);

    if (data) {
      console.log("Changed Package", packageId);
      if (packageId === "3" || packageId === "4") {
        handleCaptainsLoungeAdd(users[index]);
      }
      setUsers(...users, (users[index].tbb4_package = packageId));
    } else if (error) {
      console.log(error);
    }
  };

  const handleBotOnOff = async (bot, index, id) => {
    const { data, error } = await supabase
      .from("user_settings")
      .update({ tbb4_bot: !bot })
      .eq("id", id);

    if (data) {
      setUsers(...users, (users[index].tbb4_bot = !bot));
    } else if (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getTbb4Package();
    getUsers();
  }, []);

  React.useEffect(() => {
    getUsers();
  }, [users]);

  return (
    <div className="py-4 bg-lbw-secondary space-y-4 rounded-md mb-4">
      <div className="flex flex-wrap w-full justify-center">
        <h2 className="text-neutral-100 text-2xl font-black">User Details</h2>
        <div className="w-full max-sm:px-0 px-4 overflow-x-auto">
          {users.length > 0 && packages.length > 0 ? (
            <table className="w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="max-sm:hidden px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 font-semibold text-left">
                    Name
                  </th>
                  <th className="max-sm:px-1 max-sm:sticky max-sm:z-10 max-sm:left-0 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Email
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                    Package
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Bot On/Off
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    TBB4 Stake
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    TBB4 TP
                  </th>
                  <th className="max-sm:px-1 px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    TBB4 SL
                  </th>
                  <th className="px-6 bg-lbw-secondary text-lbw-accent  align-middle border border-solid border-slate-100 py-3 text-sm uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Last Signed in
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <tr
                    className={`border-b-2  font-bold text-neutral-100`}
                    key={item.ud_id}
                  >
                    <td className="max-sm:hidden border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {item.first_name} {item.last_name}
                    </td>
                    <td className="max-sm:break-words max-sm:sticky max-sm:z-10 max-sm:left-0 max-sm:px-0 max-sm:bg-lbw-secondary border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.email}
                    </td>
                    <td className="border-t-0 w-fit px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <select
                        value={item.tbb4_package}
                        onChange={(e) =>
                          handlePackageChange(e, index, item.usub_id)
                        }
                        className="bg-lbw-secondary-lighter text-neutral-100 border-0 px-3 py-1 w-fit  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      >
                        {packages.map((p, index) => (
                          <option value={p.id}>{p.name}</option>
                        ))}
                      </select>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={item.tbb4_bot}
                          onChange={() => {}}
                          onClick={() =>
                            handleBotOnOff(item.tbb4_bot, index, item.us_id)
                          }
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      </label>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ${parseFloat(item.tbb4_stake_size)}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ${parseFloat(item.tbb4_take_profit)}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ${parseFloat(item.tbb4_stop_loss)}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {moment(item.last_sign_in_at).format("DD-MM-YYYY HH:mmA")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTable;
